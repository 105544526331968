@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-custom::-webkit-scrollbar-button {
  display: none;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: #e5e7eb;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
}

/* For Firefox */
.scrollbar-custom {
  scrollbar-width: thin;
  scrollbar-color: #f59e0b #e5e7eb; /* Thumb and track colors */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0;
}
